import _objectSpread from "C:/xampp/htdocs/clientesapp/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
export default {
  name: "PzTitle",
  data: function data() {
    return {
      breadcrumbList: []
    };
  },
  computed: _objectSpread({}, mapGetters(['header'])),
  watch: {
    '$route': function $route() {
      this.updateList();
    }
  },
  mounted: function mounted() {
    this.updateList();
  },
  methods: {
    updateList: function updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    }
  }
};