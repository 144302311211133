import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";

/**
 * Converts a string to lowercase
 * 
 * 'AbC' => 'abc'
 */
function lowercase(value) {
  return value || value === 0 ? value.toString().toLowerCase() : '';
}

export default lowercase;