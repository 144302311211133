var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { attrs: { prop: _vm.prop, rules: _vm.rule, error: _vm.errors } },
    [
      _c(
        "el-button",
        {
          staticClass: "bottom-link pz-font-size-bs",
          attrs: { slot: "label", type: "text" },
          on: {
            click: function($event) {
              _vm.centerDialogVisible = true
            }
          },
          slot: "label"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.name
                  ? _vm.$t("see_terms", { name: _vm.name })
                  : _vm.$t("see_terms_and_conditions")
              ) +
              " "
          )
        ]
      ),
      _c("el-checkbox", {
        attrs: {
          label: _vm.name
            ? _vm.$t("accept_terms", { name: _vm.name })
            : _vm.$t("accept_terms_and_conditions")
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.terms,
          callback: function($$v) {
            _vm.terms = $$v
          },
          expression: "terms"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm._f("capitalize")(
              _vm.name ? _vm.name : _vm.$t("terms_and_conditions"),
              { onlyFirstLetter: true }
            ),
            visible: _vm.centerDialogVisible,
            width: "80%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.centerDialogVisible = $event
            }
          }
        },
        [
          _vm._t("default"),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "button-larger-effect",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleChange(true)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.name
                        ? _vm.$t("i_accept_terms", { name: _vm.name })
                        : _vm.$t("i_accept_terms_and_conditions")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }