var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "float-right",
      attrs: { trigger: "hover" },
      on: { command: _vm.changeLocale }
    },
    [
      _c(
        "el-button",
        { staticClass: "el-dropdown-link", attrs: { type: "text" } },
        [
          _c("flag", { attrs: { iso: _vm.current_lang.flag } }),
          _vm._v(" " + _vm._s(_vm.current_lang.title) + " "),
          _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.lang, function(entry) {
          return _c(
            "el-dropdown-item",
            { key: entry.title, attrs: { command: entry.language } },
            [
              _c("flag", { attrs: { iso: entry.flag } }),
              _vm._v(" " + _vm._s(entry.title) + " ")
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }