import _objectSpread from "C:/xampp/htdocs/clientesapp/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { removeToken, removeAlias } from "@/commons/utils/auth";
export default {
  name: 'PzBanner',
  data: function data() {
    return {
      src: ""
    };
  },
  computed: _objectSpread({}, mapGetters(['header'])),
  mounted: function mounted() {},
  methods: {
    logout: function logout() {
      localStorage.removeItem('documento_id');
      localStorage.removeItem('numero_documento');
      localStorage.removeItem('person');
      removeAlias();
      removeToken();
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    viewPerfil: function viewPerfil() {
      this.$router.push({
        name: 'Profile'
      });
    }
  }
};