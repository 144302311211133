import { getInstance } from "./index";
export var authGuard = function authGuard(to, from, next) {
  var authService = getInstance();

  var fn = function fn() {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      console.log('autenticado');
      return next();
    } // Otherwise, log in


    authService.loginWithRedirect({
      appState: {
        targetUrl: to.fullPath
      }
    });
  }; // If loading has already finished, check our auth state using `fn()`


  if (!authService.loading) {
    return fn();
  } // Watch for the loading property to change before we check isAuthenticated


  authService.$watch("loading", function (loading) {
    if (loading === false) {
      return fn();
    }
  });
};