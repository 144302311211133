import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "PzTermsAndConditions",
  components: {},
  props: {
    errors: {
      type: String,
      default: null
    },
    prop: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    }
  },
  data: function data() {
    var _this = this;

    var check_Terms = function check_Terms(rule, value, callback) {
      if (!_this.terms) {
        return callback(new Error(_this.$t('rules.should_accept_terms', {
          name: _this.name
        })));
      } else {
        return callback();
      }
    };

    return {
      terms: false,
      centerDialogVisible: false,
      rule: [{
        validator: check_Terms,
        trigger: 'change'
      }]
    };
  },
  watch: {
    value: function value(_value) {
      this.setTerms(_value);
    }
  },
  created: function created() {
    this.setTerms(this.value);
  },
  methods: {
    handleChange: function handleChange(val) {
      this.terms = val;
      this.$emit('input', this.terms);
      this.centerDialogVisible = false;
    },
    getInput: function getInput() {
      return this.terms;
    },
    setTerms: function setTerms(value) {
      var input = this.getInput();
      if (input === value) return;
      this.terms = value;
    }
  }
};