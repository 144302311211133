import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.keys";
import Emitter from 'element-ui/src/mixins/emitter';
import { isEmpty } from '@/commons/utils/functions';
import Errors from '@/commons/utils/Errors';
import mime from 'mime-types';
import { getFromLocal, saveInLocal } from "@/commons/utils/storage";
export default {
  name: 'PzBaseForm',
  mixins: [Emitter],
  props: {
    errors: {
      type: Errors,
      default: function _default() {
        return new Errors();
      }
    },
    inLocal: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      valid: false,
      reference: 'formulario'
    };
  },
  created: function created() {
    this.getLocal();
  },
  mounted: function mounted() {
    this.$on('validate', this.handleValidate);
  },
  methods: {
    handleValidate: function handleValidate(property, valid) {
      var dotPosition = property.indexOf(".");
      var first = property.substring(0, dotPosition);

      if (valid) {
        if (first) {
          this.$refs[this.reference].clearValidate(first);
        }

        this.$refs[this.reference].clearValidate(property);
        this.errors.clear();
      }
    },
    handleSave: function handleSave() {
      var _this = this;

      var emit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$store.dispatch('loader/up', {
        trigger: 'handle_save'
      });
      this.$refs[this.reference].validate(function (valid, invalidFields) {
        if (valid) {
          _this.$nextTick(function () {
            _this.$emit('input', _this.$refs[_this.reference].model);

            _this.savelocal();

            if (_this.valid && emit) {
              _this.$emit('save', _this.$refs[_this.reference].model);
            }
          });

          _this.valid = true;
        } else {
          if (!isEmpty(_this.$refs[Object.keys(invalidFields)[0]])) _this.$refs[Object.keys(invalidFields)[0]].focus();
          _this.valid = false;
          return false;
        }
      });
      this.$store.dispatch('loader/down', {
        trigger: 'handle_save'
      });
      return this.valid;
    },
    handleBack: function handleBack() {
      this.$emit('back');
    },
    resetForm: function resetForm() {
      this.$refs[this.reference].resetFields();
    },
    resetField: function resetField(field) {
      var index = Object.keys(this.formulario1).indexOf(field);
      this.$refs[this.reference].fields[index].resetField();
    },
    addAttachment: function addAttachment(request) {
      this.$refs[this.reference].model[request.data.fild] = request.file;
      this.$refs[this.reference].model[request.data.fild]['icon'] = this.getFileTypeExtencions(request.file.type);
    },
    getFileTypeExtencions: function getFileTypeExtencions(fileType) {
      return mime.extension(fileType);
    },
    getFileExtencionsType: function getFileExtencionsType(fileType) {
      return mime.extension(fileType);
    },
    savelocal: function savelocal() {
      if (!isEmpty(this.inLocal)) {
        saveInLocal(this.inLocal, this.$refs[this.reference].model);
      }
    },
    getLocal: function getLocal() {
      if (!isEmpty(this.inLocal)) {
        this.$store.dispatch('loader/up', {
          trigger: 'get_local'
        });

        try {
          var data = getFromLocal(this.inLocal);

          if (!isEmpty(data)) {
            this[this.reference] = data;
          }

          this.$store.dispatch('loader/down', {
            trigger: 'get_local'
          });
        } catch (error) {
          this.$store.dispatch('loader/down', {
            trigger: 'get_local'
          });
        }
      }
    }
  }
};