import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import Vue from 'vue';
import SvgIcon from '@/commons/svgIcon'; // svg component
// register globally

Vue.component('pz-svg-icon', SvgIcon);

var req = require.context('./svg', true, /\.svg$/i);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

requireAll(req);