import "core-js/modules/es.array.includes";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.includes";
import axios from 'axios';
import store from '@/store';
import i18n from "@/lang/lang_config";
import router from "@/routes/app/routes"; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api/',
  // url = base url + request url
  lang: i18n.locale,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  try {
    var hasToken = localStorage.getItem('TOKEN_CLIENTES');

    if (hasToken) {
      config.headers['Authorization'] = 'Bearer ' + hasToken;
    }

    return config;
  } catch (error) {
    return Promise.reject(error);
  }
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
    return response.data;
  }

  return response;
}, function (error) {
  var config = error.config;

  switch (error.response.status) {
    case 500:
      store.dispatch('app/setMessage', i18n.tc('connexion_error'));
      return Promise.reject(error);

    case 403:
    case 402:
    case 419:
    case 401:
      if (!config.url.includes('autorizacionfront')) {
        return refreshToken(config);
      } else {
        store.dispatch('auth/resetToken');
        store.dispatch('auth/notAuthorized');
      }

      break;

    default:
      return Promise.reject(error);
  }
});

function refreshToken(originalRequest) {
  if (!originalRequest._retry) {
    originalRequest._retry = true;
    return store.dispatch('auth/login', router.currentRoute.params.alias).then(function () {
      return service(originalRequest);
    });
  }
}

export default service;