import { render, staticRenderFns } from "./PzTermsAndConditions.vue?vue&type=template&id=7ef8b6d0&scoped=true&"
import script from "./PzTermsAndConditions.vue?vue&type=script&lang=js&"
export * from "./PzTermsAndConditions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef8b6d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\clientesapp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ef8b6d0')) {
      api.createRecord('7ef8b6d0', component.options)
    } else {
      api.reload('7ef8b6d0', component.options)
    }
    module.hot.accept("./PzTermsAndConditions.vue?vue&type=template&id=7ef8b6d0&scoped=true&", function () {
      api.rerender('7ef8b6d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/commons/forms/Fields/PzTermsAndConditions.vue"
export default component.exports