import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PzButton',
  props: {
    buttonName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'success'
    },
    icon: {
      type: String,
      default: 'el-icon-inv'
    },
    keyPress: {
      type: [Number],
      default: null
    }
  },
  componentName: 'PzButton',
  data: function data() {
    return {
      label: this.buttonName
    };
  },
  created: function created() {
    window.addEventListener('keydown', this.handleKeyDown, true);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown, true);
  },
  methods: {
    handleClick: function handleClick(evt) {
      this.$emit('click', evt);
    },
    handleKeyDown: function handleKeyDown(event) {
      if (this.keyPress && event.keyCode === this.keyPress) {
        this.handleClick();
      }
    }
  }
};