import "core-js/modules/es.object.keys";
import { merge } from "@/commons/utils/functions";
import { getPersona } from "@/routes/api/Clientes/resources";

var getDefaultState = function getDefaultState() {
  return {
    applicant: {}
  };
};

var state = getDefaultState();
var mutations = {
  SET_APPLICANT: function SET_APPLICANT(state, data) {
    state.applicant = merge(state.applicant, data);
  },
  RESSET_APPLICANT: function RESSET_APPLICANT(state) {
    state.applicant = getDefaultState().applicant;
  }
};
var actions = {
  setApplicant: function setApplicant(_ref) {
    var commit = _ref.commit;
    var client = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    commit('SET_APPLICANT', client);

    this._vm.$session.set('applicant', state.applicant);
  },
  resetApplicant: function resetApplicant(_ref2) {
    var commit = _ref2.commit;
    commit('RESSET_APPLICANT');

    this._vm.$session.set('applicant', state.applicant);
  },
  fetchApplicant: function fetchApplicant(_ref3) {
    var _this = this;

    var commit = _ref3.commit,
        state = _ref3.state;

    if (Object.keys(state.applicant).length === 0) {
      getPersona(localStorage.getItem('CLIENTE')).then(function (res) {
        var resultado = res.data;
        _this.documento_id = resultado.tipo_de_documento_id;
        _this.numero_documento = resultado.numero_de_documento;
        localStorage.setItem('documento_id', _this.documento_id);
        localStorage.setItem('numero_documento', _this.numero_documento);
        localStorage.setItem('person', JSON.stringify(resultado));
        commit('SET_APPLICANT', resultado);

        _this._vm.$session.set('applicant', state.applicant);
      });
    }
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};