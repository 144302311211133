var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "nav pz-banner-item" },
    [
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "pz-banner-item_0", attrs: { span: 24 } },
            [
              _c(
                "el-menu",
                { staticClass: "el-menu-demo", attrs: { mode: "horizontal" } },
                [
                  _c(
                    "el-menu-item",
                    { attrs: { index: "1" } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "HomeClientes" } } },
                        [
                          _c("img", { staticClass: "logo-banner" }),
                          _c("label", { staticClass: "pz-name-empresa" }, [
                            _vm._v("Prezzta")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.$auth.isAuthenticated
                    ? _c(
                        "el-menu-item",
                        { attrs: { index: "2" } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "pz-label-banner",
                              attrs: { to: { name: "Prestamos" } }
                            },
                            [_vm._v(" Prestamos ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.isAuthenticated
                    ? _c(
                        "el-submenu",
                        {
                          staticClass: "pz-banner-item_4 hidden-xs",
                          attrs: { index: "5" }
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("img", {
                              staticClass: "user-image",
                              attrs: {
                                src: require("@/assets/images/user.jpg")
                              }
                            })
                          ]),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "2-1" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "Profile" } } },
                                [_vm._v(" Perfil ")]
                              )
                            ],
                            1
                          ),
                          _c("el-menu-item", { attrs: { index: "2-2" } }, [
                            _c("a", { on: { click: _vm.logout } }, [
                              _vm._v("Cerrar Sesion")
                            ])
                          ])
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("el-menu-item", {
                    staticClass: "pz-banner-item_3 hidden-xs",
                    staticStyle: { float: "right" },
                    attrs: { index: "4", span: 1, disabled: "" }
                  }),
                  _c("el-menu-item", {
                    staticClass: "pz-banner-item_2 hidden-xs",
                    staticStyle: { float: "right" },
                    attrs: { index: "3", span: 1, disabled: "" }
                  }),
                  _c("el-menu-item", {
                    staticClass: "pz-banner-item_1 hidden-xs",
                    staticStyle: { float: "right" },
                    attrs: { index: "3", span: 1, disabled: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }