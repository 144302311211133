var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "h-100" },
    [
      _c(
        "el-container",
        {
          directives: [
            {
              name: "pz-loading",
              rawName: "v-pz-loading.fullscreen.lock",
              value: _vm.loader.run,
              expression: "loader.run",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          staticStyle: { height: "100%" },
          attrs: {
            "pz-loading-text": _vm.loader.text,
            "data-iframe-height": ""
          }
        },
        [
          _c(
            "el-main",
            { staticClass: "pz-main" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view", { key: _vm.key })],
                1
              ),
              _c("el-backtop", { attrs: { target: ".pz-main" } })
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "text-center pz-footer" },
            [
              _c(
                "el-row",
                {
                  staticClass:
                    "row justify-content-end h-100  align-items-center"
                },
                [
                  _c("el-col", { staticClass: "pz-font-size-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("footer_text", {
                            year: new Date().getFullYear()
                          })
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }