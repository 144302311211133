import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzTermsAndConditions from '@/commons/forms/Fields/PzTermsAndConditions';
import PzInputCoint from "@/commons/forms/Fields/PzInputCoin";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzCaptchaButton from "@/commons/forms/Buttons/PzCaptchaButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import PzButton from "@/commons/forms/Buttons/PzButton";
import PzLanguage from "@/lang/PzLanguage";
import PzTitle from '@/commons/titles/PzTitle';
import PzLoaderMixins from '@/commons/PzLoaderMixins';
import PzIdentityDocument from '@/commons/forms/Fields/PzIdentityDocument';
import PzUpload from '@/commons/forms/Fields/PzUpload';
export default [PzUpload, PzIdentityDocument, PzLoaderMixins, PzButton, PzBackButton, PzForwardButton, PzCaptchaButton, PzLanguage, PzBaseForm, PzTitle, PzInputCoint, PzTermsAndConditions];