var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.template_
        ? _c("el-col", [
            _c("label", { staticClass: "s-small" }, [
              _c("i", { staticClass: "el-icon-download large" }),
              _c(
                "a",
                {
                  staticClass: "s-small bottom-link",
                  attrs: { href: _vm.template_, target: "_blank" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "forms_flujo.form_documentacion_empresa.download_template"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _c(
        "el-upload",
        {
          attrs: {
            drag: "",
            action: "",
            "show-file-list": false,
            "http-request": _vm.addAttachment
          }
        },
        [
          !_vm.file_
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("i", { staticClass: "el-icon-upload" })
                  ]),
                  _c("span", { staticClass: "el-upload__text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("forms_flujo.form_documentacion_empresa.drag")
                        ) +
                        " "
                    ),
                    _c("em", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("forms_flujo.form_documentacion_empresa.click")
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _c(
                "el-col",
                {
                  staticClass: "el-upload__text pz-file-uploaded",
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "pz-file-icon", attrs: { span: 24 } },
                    [
                      _c("pz-svg-icon", {
                        style: "color:" + _vm.secondary,
                        attrs: { "icon-class": _vm.icon }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "el-upload__text" }, [
                    _vm._v(" " + _vm._s(_vm._f("truncate")(_vm.file_.name, 60)))
                  ])
                ],
                1
              ),
          _vm.tip_
            ? _c(
                "el-col",
                {
                  staticClass: "pz-font-size-sm",
                  staticStyle: { transform: "translateY(-18px)" },
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [
                  _c("i", {
                    class:
                      "el-icon-" +
                      _vm.tipIcon +
                      " pz-font-size-bs  pz-color-primary"
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(" " + _vm._s(_vm.tip_))])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }