import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import components from '@/commons/components';
import merge from 'element-ui/src/utils/merge';
import PzLoading from '@/commons/loading';
import Loading from "element-ui/packages/loading";

var install = function install(Vue) {
  components.forEach(function (component) {
    Vue.component(component.name, component);
  });
  Vue.use(PzLoading.directive);
  Vue.prototype.$pzLoading = Loading.service;
};
/* istanbul ignore if */


if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default merge({
  version: '1.0.0',
  install: install
}, components);