import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";

/**
 * Converts a string to UPPERCASE
 * 
 * 'abc' => 'ABC'
 */
function uppercase(value) {
  return value || value === 0 ? value.toString().toUpperCase() : '';
}

export default uppercase;