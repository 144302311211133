import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PzForwardButton',
  props: {
    buttonName: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'primary'
    },
    forward: {
      type: Function,
      required: true
    },
    icon: {
      type: String,
      default: 'el-icon-inv'
    },
    keyPress: {
      type: [Number],
      default: 13
    }
  },
  data: function data() {
    return {
      label: this.buttonName ? this.buttonName : this.$t('continue')
    };
  },
  created: function created() {
    window.addEventListener('keydown', this.handleEnter, true);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this.handleEnter, true);
  },
  methods: {
    handleEnter: function handleEnter(event) {
      if (this.keyPress && event.keyCode === this.keyPress) {
        this.handleClick();
      }
    },
    handleClick: function handleClick() {
      var _this = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      return new Promise(function (resolve) {
        var forw = _this.forward();

        resolve(forw);
      }).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};