import VueI18n from "vue-i18n";
import Vue from 'vue';
import settings from '@/settings/settings';
import en from "@/lang/en";
import uy from "@/lang/uy";
import es from "@/lang/es";
import ar from "@/lang/ar";
Vue.use(VueI18n);
export var languge_list = [{
  flag: 'us',
  language: 'en',
  title: 'English'
}, {
  flag: 'uy',
  language: 'uy',
  title: 'Español'
}, {
  flag: 'es',
  language: 'es',
  title: 'Español'
}, {
  flag: 'ar',
  language: 'ar',
  title: 'Español'
}];
var messages = {
  uy: uy,
  es: es,
  en: en,
  ar: ar
};
var i18n = new VueI18n({
  // set locale
  locale: settings.default_lang,
  // set fallback locale
  fallbackLocale: 'uy',
  // set locale messages
  messages: messages
});
export default i18n;