import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from "vue-router";
import i18n from "@/lang/lang_config";
import store from "@/store";
import Index from "@/views/Index";
import { getLang } from "@/commons/utils/auth";
export function lazyLoad(view) {
  return function () {
    return import("@/views/".concat(view, ".vue"));
  };
}
import { authGuard } from "../../auth/authGuard";
Vue.use(Router);
var routes = [{
  path: '/',
  name: 'App',
  component: Index,
  beforeEnter: authGuard,
  redirect: {
    name: 'Prestamos'
  },
  children: [{
    path: "/404",
    component: lazyLoad('page_errors/PzNotFound'),
    name: "404",
    meta: {
      hidden: true,
      title: 'errors._404.header'
    }
  }, {
    path: "/401",
    component: lazyLoad('page_errors/PzNotAuthorized'),
    name: "401",
    meta: {
      hidden: true,
      title: 'errors._401.header'
    }
  }, {
    path: "home",
    component: lazyLoad('portal_clientes/Home'),
    name: "HomeClientes",
    hidden: true,
    props: true,
    meta: {
      title: 'Home',
      hidden: true
    }
  }, {
    path: 'login',
    component: lazyLoad('Login'),
    name: "Login",
    hidden: true,
    props: true,
    meta: {
      title: 'Login',
      hidden: true
    }
  }, {
    path: 'prestamos',
    component: lazyLoad('portal_clientes/ListadoPrestamo'),
    name: "Prestamos",
    hidden: true,
    props: true,
    meta: {
      title: 'Prestamos',
      hidden: true
    },
    beforeEnter: authGuard
  }, {
    path: "prestamo/:loan",
    component: lazyLoad('portal_clientes/ViewPrestamo'),
    hidden: true,
    name: "ViewPrestamo",
    props: true,
    meta: {
      title: 'Prestamo',
      hidden: true,
      breadcrumb: [{
        name: 'Prestamos',
        link: 'Prestamos'
      }, {
        name: 'Prestamo'
      }]
    },
    beforeEnter: authGuard
  }, {
    path: 'perfil',
    component: lazyLoad('portal_clientes/Profile'),
    name: "Profile",
    hidden: true,
    props: true,
    meta: {
      title: 'Perfil',
      hidden: true
    },
    beforeEnter: authGuard
  }, {
    path: "/error",
    component: lazyLoad('portal_clientes/EmailNoVerificado'),
    hidden: true,
    props: true,
    name: "Email_no_verificado",
    meta: {
      title: 'not_available',
      hidden: true
    }
  }]
}, {
  path: '/callback',
  component: lazyLoad('portal_clientes/Callback')
},
/**
 * Portal Cliente**/
// {
//     path: "/clientes",
//     name: "Clientes",
//     component: Index,
//     meta: {title: "Home", show: false},
//     redirect: {path: 'clientes/home'},
//     children: clientes
// },

/**
 * Errors**/
{
  path: "*",
  hidden: true,
  redirect: {
    path: "/404"
  },
  meta: {
    hidden: true
  }
}];
var router = new Router({
  routes: routes,
  mode: 'history'
});
var whiteList = ['404', '401'];
router.beforeEach(function (to, from, next) {
  store.dispatch('loader/up', {
    trigger: 'route enter'
  });
  var Lang = getLang();
  store.dispatch('app/setLocale', Lang);

  if (whiteList.indexOf(to.name) !== -1) {
    store.dispatch('app/setHeader', {
      title: i18n.tc(to.meta.title),
      sub_title: i18n.tc(to.meta.sub_title)
    });
    next();
  } else {
    store.dispatch('app/setHeader', {
      title: i18n.tc(to.meta.title),
      sub_title: i18n.tc(to.meta.sub_title)
    });
    store.dispatch('app/googleTagManger', i18n.tc(to.meta.title));
    next();
  }
});
export default router;