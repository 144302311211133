var getters = {
  /**APP**/
  header: function header(state) {
    return state.app.header;
  },
  location: function location(state) {
    return state.app.location;
  },
  address: function address(state) {
    return state.app.address;
  },

  /**END APP**/

  /**AUTH**/
  token: function token(state) {
    return state.auth.token;
  },
  alias: function alias(state) {
    return state.auth.alias;
  },
  company: function company(state) {
    return state.auth.company;
  },

  /**END AUTH**/

  /**LOADER**/
  loader: function loader(state) {
    return state.loader.loader;
  },

  /**END LOADER**/

  /**PRODUCTO**/
  applicant: function applicant(state) {
    return state.product.applicant;
  }
  /**END PRODUCTO**/

};
export default getters;