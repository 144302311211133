import { render, staticRenderFns } from "./PzTitle.vue?vue&type=template&id=fe4d6e0c&scoped=true&"
import script from "./PzTitle.vue?vue&type=script&lang=js&"
export * from "./PzTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe4d6e0c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\clientesapp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fe4d6e0c')) {
      api.createRecord('fe4d6e0c', component.options)
    } else {
      api.reload('fe4d6e0c', component.options)
    }
    module.hot.accept("./PzTitle.vue?vue&type=template&id=fe4d6e0c&scoped=true&", function () {
      api.rerender('fe4d6e0c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/commons/titles/PzTitle.vue"
export default component.exports