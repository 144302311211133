import _objectSpread from "C:/xampp/htdocs/clientesapp/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import loderMixins from "@/commons/PzLoaderMixins";
export default {
  name: 'App',
  mixins: [loderMixins],
  data: function data() {
    return {
      windowHeight: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['loader', 'location'])), {}, {
    key: function key() {
      return this.$route.path;
    }
  }),
  inject: ['app'],
  provide: function provide() {
    return {
      app: this.app
    };
  },
  methods: {}
};