var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "pz-large-margin", attrs: { span: 24 } },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 18 } }, [
            _c("div", { staticClass: "pz-title" }, [_vm._t("title")], 2)
          ]),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-breadcrumb",
                {
                  staticStyle: { "text-align": "right", "padding-top": "15px" },
                  attrs: { separator: "/" }
                },
                _vm._l(_vm.breadcrumbList, function(breadcrumb, index) {
                  return _c(
                    "el-breadcrumb-item",
                    { key: index, attrs: { to: { name: breadcrumb.link } } },
                    [_vm._v(" " + _vm._s(breadcrumb.name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }